<template>
  <div>
    <h2 class="th-title">Banner (Tidak Terhubung dengan Course)</h2>
    <a-card>
      <div class="d-flex justify-content-between mb-3">
        <a-input
          style="width: 200px"
          v-model:value="search"
          @change="handleSearchBanner"
          placeholder="Cari Nama Banner"
        >
          <template #prefix>
            <span>
              <search-outlined style="color: rgba(0, 0, 0, 0.25)" />
            </span>
          </template>
        </a-input>
        <a-button @click.prevent="showModalAdd">
          <template #icon><plus-outlined /></template>
          Tambah
        </a-button>
        <a-modal v-model:visible="modalAddVisible" title="Tambahkan Banner" @ok="handleOk">
          <a-form layout="vertical" :model="formAdd">
            <a-form-item required label="Nama" name="name" v-bind="validateInfos.name">
              <a-input placeholder="Masukkan nama banner" v-model:value="formAdd.name" />
            </a-form-item>
            <a-form-item label="Deskripsi">
              <a-textarea :rows="6" placeholder="Masukkan deskripsi (opsional)" v-model:value="formAdd.description" show-count :maxlength="100" />
            </a-form-item>
            <a-form-item required label="URL">
              <a-input placeholder="Masukkan url banner" v-model:value="formAdd.url" />
            </a-form-item>
            <a-form-item required label="Status">
              <a-switch v-model:checked="formAdd.isActive" @click="(e) => onStatusClick(e, 'add')"/>
            </a-form-item>
            <a-upload
              list-type="picture-card"
              :multiple="false"
              :before-upload="beforeUpload"
              v-model:value="imageBanner"
              @preview="handlePreviewImage"
              @change="handleChangeImage"
            >
              <div v-if="imageBanner === null">
                <div class="ant-upload-text">
                  Upload
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisibleImage" :footer="null" @cancel="handleCancelImage">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form>
          <template #footer>
            <a-button @click.prevent="confirmAdd" key="submit" type="primary" html-type="submit">Tambahkan</a-button>
          </template>
        </a-modal>
      </div>
      <div>
        <a-table :columns="columns" :data-source="data" :loading="loadingTable" bordered>
          <template #name="{ text, record }">
            <a-form layout="vertical" v-if="record.key === idEdit">
              <a-form-item name="name" v-bind="validateInfosEdit.name">
                <a-input v-model:value="formEdit.name" />
              </a-form-item>
            </a-form>
            <span v-else>
              {{ text }}
            </span>
          </template>
          <template #description="{ text, record }">
            <span v-if="record.key === idEdit">
              <a-textarea v-model:value="formEdit.description" />
            </span>
            <span v-else>
              {{ text || '-' }}
            </span>
          </template>
          <template #image="{ text, record }">
            <span v-if="record.key === idEdit">
              <!-- <a-textarea v-model:value="formEdit.image" /> -->
              <a-upload
                list-type="picture-card"
                :multiple="false"
                :before-upload="beforeUpload"
                v-model:value="imageBanner"
                @preview="handlePreviewImage"
                @change="handleChangeImage"
              >
                <div v-if="imageBanner === null">
                  <div class="ant-upload-text">
                    Upload
                  </div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisibleImage" :footer="null" @cancel="handleCancelImage">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </span>
            <span v-else>
              <img :alt="text" style="width: 100%" :src="`${backendUrlSlice}${text}`" />
            </span>
          </template>
          <template #url="{ text, record }">
            <span v-if="record.key === idEdit">
              <a-input v-model:value="formEdit.url" />
            </span>
            <span v-else>
              {{ text || '-' }}
            </span>
          </template>
          <template #isActive="{ text, record }">
            <div v-if="record.key === idEdit">
              <a-form layout="vertical" v-if="record.key === idEdit">
                <a-form-item name="isActive" v-bind="validateInfosEdit.isActive">
                  <a-switch v-model:checked="formEdit.isActive" @click="(e) => onStatusClick(e, 'edit')"/>
                </a-form-item>
              </a-form>
              <span v-else>
                {{ text }}
              </span>
            </div>
            <div v-else>
              <a-tag class="align-self-start" v-if="text === true" color="success">
                <template #icon>
                  <check-circle-outlined />
                </template>
                Aktif
              </a-tag>
              <a-tag class="align-self-start" v-else color="error">
                <template #icon>
                  <close-circle-outlined />
                </template>
                Tidak Aktif
              </a-tag>
            </div>
          </template>
          <template #action="{ record }">
            <span v-if="record.key === idEdit">
              <a @click.prevent="confirmEdit(record)">
                <check-outlined></check-outlined>
              </a>
              <a-divider type="vertical" />
              <a @click.prevent="closeEdit">
                <close-outlined></close-outlined>
              </a>
            </span>
            <span v-else>
              <a @click.prevent="bannerAction(record, 'UPDATE')">Ubah</a>
              <a-divider type="vertical" />
              <a @click.prevent="bannerAction(record, 'DELETE')">Hapus</a>
            </span>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
import { onMounted, reactive, ref, toRaw } from 'vue';
import { useStore } from 'vuex';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import { useForm } from '@ant-design-vue/use';
// import axios from 'axios'
import apiClient from '@/services/axios'

const columns = [
  {
    title: 'Nama',
    align: 'center',
    width: 100,
    dataIndex: 'name',
    key: 'name',
    slots: { customRender: 'name' },
  },
  {
    title: 'Deskripsi',
    align: 'center',
    width: 200,
    dataIndex: 'description',
    key: 'description',
    slots: { customRender: 'description' },
  },
  {
    title: 'Gambar',
    align: 'center',
    width: 200,
    dataIndex: 'image',
    key: 'image',
    slots: { customRender: 'image' },
  },
  {
    title: 'URL',
    align: 'center',
    width: 200,
    dataIndex: 'url',
    key: 'url',
    slots: { customRender: 'url' },
  },
  {
    title: 'Status',
    align: 'center',
    width: 100,
    dataIndex: 'isActive',
    key: 'isActive',
    slots: { customRender: 'isActive' },
  },
  {
    title: 'Aksi',
    align: 'center',
    width: 200,
    // fixed: 'right',
    key: 'action',
    slots: { customRender: 'action' },
    align: 'center',
  },
];

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
  },
  setup() {
    const store = useStore()
    let data = ref([])
    let imageBanner = ref(null)
    let search = ref(null)
    let loadingTable = ref(false)
    let modalAddVisible = ref(false)
    let newFileStatus = ref(true)
    let previewVisibleImage = ref(false)
    let previewImage = ref(null)
    let backendUrl = ref(process.env.VUE_APP_BACKEND_URL)
    let backendUrlSlice = ref(null)
    let formAdd = reactive({
      name: null,
      description: null,
      url: null,
      isActive: false,
    })
    const loadingAdd = ref(false)
    const rulesRef = reactive({
      name: [
        {
          required: true,
          message: 'Nama harus diisi!',
          trigger: 'change',
        },
      ],
    })
    let formEdit = ref({
      name: null,
      description: null,
      image: null,
      url: null,
      isActive: false,
    })
    let idEdit = ref(null)

    onMounted(async () => {
      fetchBanner()
      sliceUrl()
    })

    const fetchBanner = async (search = '') => {
      loadingTable.value = true
      const rawData = await store.dispatch('banner/FETCH_BANNER', search)
      // console.log('fetchBanner rawData => ', rawData)
      loadingTable.value = false
      data.value = rawData.map(row => {
        return {
          key: row._id,
          name: row.name,
          description: row.description,
          image: row.image,
          url: row.url,
          isActive: row.isActive,
        }
      })
    }

    const showModalAdd = () => {
      modalAddVisible.value = true
    }

    const sliceUrl = () => {
      const url = backendUrl.value
      backendUrlSlice.value = url.slice(0, -1)
      console.log(backendUrlSlice.value)
    }

    const onStatusClick = (value, type) => {
      console.log('onStatusClick', value, type)
      if (type === 'add') {
        formAdd.isActive = value
      } else {
        formEdit.value.isActive = value
      }
    }

    const beforeUpload = (file) => {
      newFileStatus.value = false
      imageBanner.value = file
      // console.log('beforeUpload imageBanner =>', imageBanner)
      return false
    }

    const handleChangeImage = (value) => {
      const fileList = value.fileList
      const banner = imageBanner.value
      const imageType = banner.type
      // console.log('handleChangeImage imageType => ', imageType)
      if (imageType === 'image/jpg' || imageType === 'image/jpeg'|| imageType === 'image/png') {
        if (fileList.length) {
          notification.success({
            message: 'Berhasil Upload Image.',
          })
        } else {
          imageBanner.value = null
          notification.warning({
            message: 'Berhasil Hapus Image!',
            description: 'Mohon upload kembali image terbaru untuk banner!',
          })
        }
      } else {
        imageBanner.value = null
        newFileStatus.value = true
        notification.error({
          message: 'Gagal Upload Image!',
          description: `Tipe file ini tidak diizinkan ${imageType}! Tipe file hanya boleh: jpg / jpeg / png.`,
        })
      }
    }

    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    }

    const handlePreviewImage = async (file) => {
      try {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj)
        }
        previewImage.value = file.url || file.preview
        previewVisibleImage.value = true
      } catch(err) {
        console.log('handlePreviewImage error => ', err)
      }
    }

    const handleCancelImage = () => {
      previewVisibleImage.value = false
    }

    const handleOk = () => {
      modalAddVisible.value = false
    }

        const closeEdit = () => idEdit.value = null

    const confirmEdit = async (record) => {
      try {
        // const payload = {
        //   id: record.key,
        //   data: {
        //     name: formEdit.value.name,
        //     description: formEdit.value.description,
        //   },
        // }
        await validateEdit()
        Modal.confirm({
          title: `Apa anda yakin ingin mengubah data pada banner '${record.name}'?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            const id =  record.key
            const formData = new FormData()
            const banner = imageBanner.value
            const name = formEdit.value.name
            const description = formEdit.value.description
            const url = formEdit.value.url
            const isActive = formEdit.value.isActive
            if (banner !== null) {
              formData.append('banner', banner)
            }
            formData.append('name', name)
            formData.append('description', description)
            formData.append('url', url)
            formData.append('isActive', isActive)
            try {
              await apiClient.put(`${backendUrl.value}api/banner/update/${id}`, formData)
              imageBanner.value = null
              fetchBanner()
              notification.success({
                message: `Berhasil Mengubah Data Pada Banner '${record.name}'`,
              })
              idEdit.value = null
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch (err) {
        console.log(err)
      }
    }

    const { resetFields, validate, validateInfos } = useForm(formAdd,  rulesRef);
    const { validate:validateEdit, validateInfos:validateInfosEdit } = useForm(formEdit, rulesRef);

    const confirmAdd = async () => {
      try {
        // console.log('confirmAdd ', formAdd, imageBanner)
        loadingAdd.value = true
        await validate()
        // console.log(console.log(toRaw(formAddRef)))
        Modal.confirm({
          title: 'Apa anda yakin ingin menambahkan banner?',
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            const formData = new FormData()
            const banner = imageBanner.value
            const name = formAdd.name
            const description = formAdd.description
            const url = formAdd.url
            const isActive = formAdd.isActive
            formData.append('banner', banner)
            formData.append('name', name)
            formData.append('description', description)
            formData.append('url', url)
            formData.append('isActive', isActive)
            try {
              await apiClient.post(`${backendUrl.value}api/banner/create`, formData)
              imageBanner.value = null
              modalAddVisible.value = false
              loadingAdd.value = false
              fetchBanner()
              notification.success({
                message: `Berhasil Menambahkan '${formAdd.name}' Dari Daftar Banner`,
              })
              resetFields()
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } catch(err) {
        loadingAdd.value = false
      }
    }

    const bannerAction = async (record, action) => {
      const data = {
        id: record.key,
        data: {
          name: record.name,
          description: record.description,
        },
      }
      if (action === 'DELETE') {
        Modal.confirm({
          title: `Apa anda yakin ingin menghapus banner '${record.name}' dari daftar?`,
          icon: createVNode(ExclamationCircleOutlined),
          async onOk() {
            try {
              const res = await store.dispatch('banner/DELETE_BANNER', { ...data })
              fetchBanner()
              notification.success({
                message: `Berhasil Menghapus '${record.name}' Dari Daftar Banner`,
              })
            } catch (err) {
              console.log(err)
            }
          },
          onCancel() {},
        })
      } else if (action === 'UPDATE') {
        idEdit.value  = record.key
        formEdit.value = {
          name: record.name,
          description: record.description || null,
          image: record.image || null,
          url: record.url || null,
          isActive: record.isActive || null,
        }
        // const data = await store.dispatch(action + '_BANNER', { id, data })
      }
    }
    
    const handleSearchBanner = () => {
      fetchBanner(search.value)
    }

    return {
      data,
      columns,
      loadingTable,
      search,
      modalAddVisible,
      showModalAdd,
      onStatusClick,
      handleOk,
      formAdd,
      rulesRef,
      loadingAdd,
      confirmAdd,
      validate,
      validateInfos,
      handleChangeImage,
      newFileStatus,
      beforeUpload,
      getBase64,
      previewVisibleImage,
      previewImage,
      handlePreviewImage,
      handleCancelImage,
      imageBanner,
      validateEdit,
      validateInfosEdit,
      bannerAction,
      idEdit,
      formEdit,
      closeEdit,
      confirmEdit,
      backendUrl,
      backendUrlSlice,
      handleSearchBanner,
    }
  },
}
</script>
